<template>
  <div id="Order_list">
    <div v-for="(item, index) in list" :key="index" class="order">
      <div @click="toOrderInfo(item.id)">
        <van-cell-group>
        <!-- 订单创建时间，状态 -->
        <van-cell>
          <template #title >
            <span>{{item.create_time}}</span>
            <span class="state">{{ $getStatus(item) }}</span>
          </template>
        </van-cell>
        <!-- 订单介绍 -->
        <div v-for="(item1, index1) in item.products" :key="index1" class="card_bord">
          <van-card num="2" :title="item1.goods_title" :thumb="item1.goods_pic" class="order_center">
            <template #price >
              <span class="quantity">$</span>
              <span class="quantity">{{item1.sale_price}}</span>
            </template>
            <template #num>
              <p class="quan_tity">x{{item1.number}}</p>
              <p class="quan_tity">{{item1.product_name}}</p>
            </template>
          </van-card>
        </div>
        <!-- <van-divider /> -->
        <!-- 订单件数 -->
        <van-row class="row_margin">
          <!-- 共计商品  件 -->
          <van-col offset="11" span="12" style="text-align: right; font-size: 14px;">{{language.l_32}}{{(item.products).length}}{{language.l_33}}</van-col>
        </van-row>
        <!-- 订单合计价格 -->
        <van-row>
          <van-col class="money" offset="11" span="12" style="text-align: right;">
            <span> 合计：</span>
            <span>$</span>
            <span>{{item.expect_pay_amount}}</span>
          </van-col>
        </van-row>
        <!-- 订单修改 -->
        <van-row class="order_button" type="flex" justify="end">
          <!-- 交易取消    删除订单 -->
          <van-button v-if="$getStatus(item) == language.l_34" @click.stop="delOrders(item.id, index)" size="mini" type="default" plain round class="pay-btn">{{language.l_35}}</van-button>
          <!-- 待付款    取消订单 -->
          <van-button v-if="$getStatus(item) == language.l_25" @click.stop="closeOrder('cancel', item.id)" size="mini" type="default" plain round class="pay-btn">{{language.l_36}}</van-button>
          <!-- 待付款    重新支付 -->
          <van-button v-if="$getStatus(item) == language.l_25 && item.province" @click.stop="againPay(item.id)" size="mini" type="danger" round class="pay-btn">{{language.l_37}}</van-button>
          <!-- 待出库    自提    申请退款 -->
          <van-button v-if="($getStatus(item) == language.l_39 && item.activity_status != 1) || $getStatus(item) == language.l_40" @click.stop="closeOrder('refund', item.id)" size="mini" type="default" round class="pay-btn">{{language.l_41}}</van-button>
          <!-- 已发货    已签收    待发货    部分发货    自提    交易完成    申请售后 -->
          <van-button v-if="$getStatus(item) == language.l_42 || $getStatus(item) == language.l_43 || $getStatus(item) == language.l_44 || $getStatus(item) == language.l_45 || $getStatus(item) == language.l_40 || $getStatus(item) == language.l_46" @click.stop="toGetService(item.id, item.order_sn)" size="mini" type="default" plain round class="pay-btn">{{language.l_47}}</van-button>
          <!-- 售后中    售后进度 -->
          <van-button v-if="$getStatus(item) == language.l_48" @click.stop="toServiceInfo(item.after_sale_id)" size="mini" type="default" plain round class="pay-btn">{{language.l_49}}</van-button>
          <!-- 退款处理中    已退款    售后完成    交易完成    再去逛逛 -->
          <van-button v-if="$getStatus(item) == language.l_50 || $getStatus(item) == language.l_51 || $getStatus(item) == language.l_52 || $getStatus(item) == language.l_46" @click.stop="toHome" size="mini" type="default" plain round class="pay-btn">{{language.l_53}}</van-button>
          <!-- 已发货    自提    确认收货 -->
          <van-button v-if="$getStatus(item) == language.l_42 || $getStatus(item) == language.l_40" @click.stop="confirmRec(item.id, index)" size="mini" type="danger" round class="pay-btn">{{language.l_54}}</van-button>
        </van-row>
        </van-cell-group>
      </div>
      <!-- 回到顶部 -->
      <BackTop :bottom="100" :right="10" :hasTabbar="true"/>
    </div>
  </div>
</template>

<script>
import BackTop from '@cpt/BackTop';
import {delOrder, receivedOrder} from '@api';

export default {
  props: {list: Array},
  data() {
    return {
      language: this.$store.getters.language
    };
  },
  components: {
    BackTop
   },
   methods: {
    closeOrder(type, id) {  //取消订单
      this.$emit('openPop', type, id);
    },
    delOrders(id, index){  //删除订单
      this.$dialog.confirm({
        message: this.language.l_55,  //确定要删除吗，删除之后找不回哟~
        cancelButtonText: this.language.l_22,  //我再想想
        confirmButtonText: this.language.l_14  //刪除
      }).then(() => {
        delOrder(id).then(res => {
          this.$toast.success(this.language.l_56);  //刪除成功
          // 重载数据
          this.$emit('delOrder', index)
          this.$store.dispatch("getOrderList");
        })
      }).catch(err => this.$toast.fail(this.language.l_313))  //取消刪除
    },
    toOrderInfo(id) {  //跳转订单详情
      this.$router.push({name: "OrderInfo", params: { id }});
    },
    toHome() {  //跳转首页
      this.$router.push({name: "Home"});
    },
    toGetService(id) {  //申请售后
      this.$router.push({name: 'ServiceApply', query: {id}})
    },
    toServiceInfo(id) { // 跳转售后进度
      this.$router.push({name: 'ServiceInfo', params: {id}})
    },
    confirmRec(id, index) {  //确认收货
       this.$dialog.confirm({message: this.language.l_57}).then(() => {  //是否确认收货？
        receivedOrder({id}).then(res => {
          this.$toast.success(this.language.l_58);  //收货成功
          // 重载数据
          this.$emit('confirmRec', index)
        })
      }).catch(err => this.$toast.fail(this.language.l_59))  //取消收货
    },
    againPay(id) {  //重新支付
      this.$emit('showPay', id);
    }
  }
};
</script>

<style lang='less' scoped>
#Order_list{
  .order{
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    .card_bord{
      // border-bottom: 1px solid #9999;
      // border-color: rgba(0,0,0,.1);
      padding: 8px 0;
    }
    .row_margin{
      margin-top: 10px;
    }
    .order_center{
      margin: 0 10px;
      border-radius: 10px;
      .quan_tity{
        text-align: right;
      }
    }
    /deep/ .van-cell::after{
      border: none;
    }
    .money{
      padding-top: 8px;
      span:nth-child(1){
        font-size: 14px;
      }
      span:nth-child(2){
        font-size: 12px;
        color: red;
      }
      span:nth-child(3){
        color: red;
      }
      span:nth-child(4){
        font-size: 12px;
        color: red;
      }
      span:nth-child(5){
        font-size: 14px;
        color: red;
      }
    }
    .order_button{
      padding: 5px 10px 10px 0;
      /deep/ .van-button{
        border-radius: 6px !important;
      }
      .Payload{
        background: @grad_left;
        color: #fff;
      }
    }
    .van-cell__title{
      display: flex;
      justify-content: space-between;
    }
    .state{
      color: @c_deep;
    }
  }
}
</style>
