<template>
  <div id="orderList">
    <van-tabs @click="selectTab" v-model="active" color="red" :border="false" swipe-threshold="6" >
      <!-- 全部订单 -->
      <van-tab :title="language.l_24"></van-tab>
      <!-- 待付款 -->
      <van-tab :title="language.l_25"></van-tab>
      <!-- 待出库 -->
      <van-tab :title="language.l_39"></van-tab>
      <!-- 待发货 -->
      <van-tab :title="language.l_44"></van-tab>
      <!-- 待收货 -->
      <van-tab :title="language.l_26"></van-tab>
    </van-tabs>

    <div v-if="this.list.length" class="container">
      <van-list v-model="loading" @load="onReachBottom" :finished="finished">
        <OrderListTemp @toOrderInfo="toOrderInfo" :list="list" @delOrder="del_order" @confirmRec="confirm_rec" @openPop="openClosePop" @showPay="confirmPayment"/>
      </van-list>
    </div>
    <div v-else class="container">
      <div class="orders-bg">
        <div class="no-data"></div>
        <!-- 你没有相关订单，快去商场下单吧！ -->
        <p>{{language.l_228}}</p>
        <!-- 去逛逛 -->
        <van-button plain hairline type="info" @click="toHome">{{language.l_12}}</van-button>
      </div>
    </div>

    <!-- 取消订单弹窗 -->
    <van-popup v-model="cancelFlag" @close="cancelClose('cancel')" position="bottom" get-container="#app" safe-area-inset-bottom class="cancel-reason">
      <div class="model-wrap">
        <!-- 取消订单 -->
        <p class="model-header">{{language.l_36}}</p>
        <div class="model-wram">
          <!-- 温馨提示 -->
          <p class="model-wram-title">{{language.l_214}}</p>
          <ul class="model-wram-list">
            <!-- 订单一经取消，无法恢复 -->
            <li>{{language.l_215}}</li>
            <!-- 订单所用红包，优惠券支持退回 -->
            <li>{{language.l_216}}</li>
            <!-- 若您支付未成功，请及时联系客服 -->
            <li>{{language.l_217}}</li>
          </ul>
        </div>
        <!-- 请选择取消订单的原因： -->
        <p class="model-p">{{language.l_218}}</p>
        <div class="model-radios">
          <van-radio-group v-model="cancelRadio" :border="false" label-position="left">
            <van-cell-group :border="false">
              <van-cell v-for="(item, index) in cancelReason" :key="index" @click="cancelRadio = item.value" :title="item.name" clickable>
                <van-radio :name="item.value"/>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>
        <!-- 确定 -->
        <div class="model-btn" @click="confirmClose('cancel')">{{language.l_87}}</div>
      </div>
    </van-popup>

    <!-- 退款弹窗 -->
    <van-popup v-model="refundFlag" @close="cancelClose('refund')" position="bottom" get-container="#app" safe-area-inset-bottom class="cancel-reason">
      <div class="model-wrap">
        <!-- 申请退款 -->
        <p class="model-header">{{language.l_41}}</p>
        <!-- 请选择退款的原因： -->
        <p class="model-p">{{language.l_219}}</p>
        <div class="model-radios">
          <van-radio-group v-model="refundRadio" :border="false" label-position="left">
            <van-cell-group :border="false">
              <van-cell v-for="(item, index) in refundReason" :key="index" @click="refundRadio = item.value" :title="item.name" clickable>
                <van-radio :name="item.value" />
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>
        <!-- 确定 -->
        <div class="model-btn" @click="confirmClose('refund')">{{language.l_87}}</div>
      </div>
    </van-popup>

    <BackTop :bottom="100" :right="10" />
  </div>
</template>

<script>
import BackTop from "@cpt/BackTop";
import OrderListTemp from "@cpt/OrderListTemp";

import {orderList, cancellation, cancelOrder, getRefundReason, applyRefund, pay} from "@api";
export default {
  data() {
    return {
      params: {
        order_status: "", // 订单状态
        pay_status: "", // 发货状态
        shipping_status: "", // 支付状态
        page: 1, // 数据页码
        page_size: 10, // 数据加载条数
      },
      active: 0,
      list: [],
      count: 0,
      cancelFlag: false, // 取消订单弹窗
      cancelReason: null, // 取消订单的原因数据
      cancelRadio: null, // 选择的取消原因
      id: null, // 取消订单和退款的id
      refundFlag: false, // 退款申请弹窗
      refundRadio: null, // 退款申请原因
      refundReason: null, // 退款申请原因数据
      payment_no: null, // 退款的订单支付单号
      refreshing: false,
      loading: false,
      finished: true,
      language: this.$store.getters.language
    };
  },
  components: {
    BackTop,
    OrderListTemp,
  },
  activated() {
    if(!this.$route.meta.isUseCache) {this._created()}
    this.$route.meta.isUseCache = false;
  },
  methods: {
    _created() {
      this._resetData();
      const {active} = this.$route.query;
      this.active = active;
      this.selectTab(active);
    },
    _resetData() {
      this.params = {
        order_status: "",
        pay_status: "",
        shipping_status: "",
        page: 1,
        page_size: 10,
      }
      this.cancelFlag = false;
      this.refundFlag = false;
      this.list = [];
      this.count = 0;
    },
    toHome() {  // 跳转首页
      this.$router.push({name: "Home"});
    },
    toOrderInfo(id) {  // 跳转订单详情
      this.$router.push({name: "OrderInfo", params: {id}});
    },
    selectTab(active) {  //切换请求接口
      const {params} = this
      params.page = 1
      if(active == 0) {
        params.order_status = ''
        params.pay_status = ''
        params.shipping_status = ''
      }else if(active == 1) {
        params.order_status = 0
        params.pay_status = 0
        params.shipping_status = 0
      }else if(active == 2) {
        params.order_status = 0
        params.pay_status = 1
        params.shipping_status = 0
      }else if(active == 3) {
        params.order_status = 0
        params.pay_status = 1
        params.shipping_status = 1
      }else if(active == 4) {
        params.order_status = 0
        params.pay_status = 1
        params.shipping_status = 2
      }
      this.getData(params)
    },
    openClosePop(type, id) {  // 打开取消订单弹窗
      const {cancelReason, refundReason} = this.$store.state;
      this.id = id;
      if(type === 'cancel') {
        this.cancelFlag = true;
        if(!cancelReason) {
          cancellation().then(res => {
            this.cancelReason = res.data;
            this.$store.commit('setCancelReason', res.data);
          })
        } else {
          this.cancelReason = cancelReason
        }
      }else if (type === 'refund'){
        this.refundFlag = true;
        if(!refundReason) {
          getRefundReason().then(res => {
            this.refundReason = res.data;
            this.$store.commit('setRefundReason', res.data);
          })
        } else {
          this.refundReason = refundReason
        }
      }
    },
    cancelClose(type) { // 关闭取消弹窗
      if(type === 'cancel') {
        if(this.cancelRadio) {this.cancelRadio = null}
      }else if(type === 'refund') {
        if(this.refundRadio) {this.refundRadio = null}
      }
    },
    confirmClose(type) {  //取消订单
      const {list, cancelRadio, id, refundRadio: reason_id, payment_no} = this;
      if(type === 'cancel') {
        if (!cancelRadio) return this.$toast(this.language.l_222);  //请选择一个原因
        const index = list.findIndex((item) => item.id == id);
        cancelOrder({id, cancel_reason: cancelRadio}).then(() => {
          this.$toast.success(this.language.l_162);  //取消成功
          this.list[index].order_status = 3;
          this.list[index].shipping_status = 3;
          this.cancelFlag = false;
          this.cancelRadio = null;
          this.params.page = 1
          this.getData(this.params)
        })
      }else if(type === 'refund') {
        if (!reason_id) return this.$toast(this.language.l_222);  //请选择一个原因
        const index = list.findIndex((item) => item.id == id);
        applyRefund({ id, reason_id, payment_no }).then(() => {
          this.$toast.success(this.language.l_226);  //申请成功
          this.refundFlag = false;
          this.refundRadio = null;
          this.list[index].order_status = 5;
          this.params.page = 1
          this.getData(this.params)
        });
      } 
    },
    confirmPayment(id) {  //提交支付
      const {list} = this;
      const index = list.findIndex(item => item.id == id);
      const dataInfo = list[index]; // 获取当前付款的订单数据
      const {pay_type} = dataInfo

      let params = {
        order_id: id,
        pay_type
      }

      this.$dialog.confirm({
        title: this.language.l_37,  //重新支付
        message: this.language.l_224,  //按原支付方式支付
      }).then(() => {
         pay(params).then(() => {
            // this.$store.commit('clearOrderAdr', id); // 清除选择的地址
            this.$toast.success(this.language.l_199);  //支付成功
            this.list[index].pay_status = 1;
            // this.$store.commit("setDelayJump", () => this.$router.push({name: "OrderInfo", params: {id}})
            // );
            this.params.page = 1
            this.getData(this.params)
          })
        }).catch(() => this.$toast.fail(this.language.l_225));  //支付取消
    },
    del_order(index) { // 删除订单
      const {list} = this;
      list.splice(index, 1);
      this.count--;
    },
    confirm_rec(index) {  // 确认收货
      const {list} = this;
      list[index].shipping_status = 2;
      this.getData(this.params)
    },
    onReachBottom() {  // 上拉加载
      const {params, count, list} = this;
      const maxPage = Math.ceil(count / params.page_size); // 最大页码
      this.getData(params, res => {
        this.loading = false;
        this.list = [...list, ...res.data];
        this.params.page++;
        if(this.params.page > maxPage) {
          this.params.page--;
          this.finished = true;
        }
      })
    },
    onRefresh() {  // 下拉刷新
      setTimeout(() => (this.refreshing = false), 1000);
      this.params.page = 1;
      this.getData(this.params)
    },
    getData(params, callback) {
      orderList(params).then(res => {
        if(callback) return callback(res.data);
        const {data: list, count} = res.data;
        this.refreshing = false;
        this.finished = true;
        this.list = list;
        this.count = count;

        if(count > params.page_size) { // 若数据大于1页条数，则允许上拉加载
          this.finished = false;
          this.params.page++;
        }
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    const route_name = ["GoodsInfo", "OrderInfo", "OrderRate", "ServiceApply", "ServiceInfo", "ActGroup"];
    if (route_name.includes(to.name)) {from.meta.isUseCache = true}
    next();
  },
};
</script>

<style lang="less" scoped>
#orderList {
  height: 100%;
  .van-pull-refresh {
    height: 100%;
  }
  .safe_padding_bottom();
  .van-tabs {
    /deep/ .van-tab--active {
      span {
        font-weight: bold;
      }
    }
  }
  .container {
    width: 355px;
    margin: 10px auto 0;
    .safe_padding_bottom();
    .orders-bg {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 100px;
      .no-data {
        width: 300px;
        height: 200px;
        margin: 0 auto;
        background-image: url("~@img/order/no-order.png");
        background-size: 100%;
        background-position: center;
      }
      p {
        color: #999;
        margin: 10px 0 20px;
      }
      .van-button {
        width: 80px;
        height: 35px;
        line-height: 35px;
        color: @c_light;
        border-color: @c_light;
        background-color: transparent;
      }
    }
  }
}
</style>